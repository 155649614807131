const jhonData = {
  name: "John Deo.",
  name2: "Richard Tea.",
  name3: "Alexa Johnson",
  role: "Visual Designer & Front-End Developer,",
  ptext: "Creative Designer & Developer located in London.",
  socialLinks: [
    {
      name: "github",
      url: "https://github.com/KaiDoingCode",
      className: "social_blogger_square",
    },
    {
      name: "email",
      url: "mailto:vantu20896@gmail.com",
      className: "social_googleplus",
    },
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/kai-phung",
      className: "social_linkedin",
    },
  ],
  aboutme: "About Me - Tu Phung",
  aboutdetails:
    "Qualified Software Trainee seeking a position in which to put my education, skills and experience to work with a well-established organization. Enthusiastic and motivated software developer student, who soon to be graduated from Tampere university of applied sciences, seeks for internship opportunity to use proven skills in Javascript, C#, AWS and CircleCI to meet company needs.",
  service: [
    {
      id: 1,
      iconName: "icon-desktop",
      serviceTitle: "Web Design",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 2,
      iconName: "icon-pencil",
      serviceTitle: "Development",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 3,
      iconName: "icon-presentation",
      serviceTitle: "Marketing",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 4,
      iconName: "icon-linegraph",
      serviceTitle: "Strategy",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 5,
      iconName: "icon-gears",
      serviceTitle: "Support",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 6,
      iconName: "icon-tools",
      serviceTitle: "We’re experienced",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
  ],
  contact: [
    {
      id: "1",
      title: "Contact Info",
      menuItems: [
        {
          id: 1,
          icon: "icon_pin_alt",
          text: "Address: Tampere - Finland",
        },
        {
          id: 2,
          icon: "icon_phone",
          text: "Phone: +358.41.493.0483",
        },
        {
          id: 3,
          icon: "icon_mail",
          text: "Email: vantu20896@gmail.com",
        },
        {
          id: 4,
          icon: "icon_globe",
          text: "Website: https://github.com/KaiDoingCode",
        },
      ],
    },
  ],
  devloperBanner: [
    {
      id: 1,
      subtile: "Full-stack Software Developer",
      title: "Tu Phung",
      discription:
      "I am a passionate software developer who loves crafting products that help users with their work.\n I am a curious individual, constantly driven to inquire, explore, and seek well-researched answers to the questions I pose.\n I care about making clients happy, teamwork, and code quality. Looking for junior to mid-level role.",
    },
  ],
  devAbout: [
    {
      id: 1,
      smalltitle: "Introduction",
      aHead: "Passionate Coder",
      aHeadTwo: "Full-stack Software Developer",
      adiscription:
        "Great problem-solving skills with a strong background in data analysis. Accustomed to international working environments, having worked in an international agency and Finnish software consulting agency, that requires intensive problem-solving skills and responsibilities.",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: "Back-End",
      text: "C#, .NET, Java, Node.js, Typescript",
      complateProject: "Hands-on experience in C# and .NET for building with backend systems and enterprise applications. Skilled in Java and Node.js for cross-platform and real-time application development",
      icon: "icon-compass",
    },
    {
      id: 2,
      workName: "Front-End",
      text: "React, Angular",
      complateProject: "Hands-on experience in utilizing both React and Angular for developing dynamic, responsive, and user-friendly web applications. Frontend & UI implementation from Figma design.",
      icon: "icon-genius",
    },
    {
      id: 3,
      workName: "Cloud",
      text: "Azure, AWS",
      complateProject: "Proficient in Azure cloud services, including deploying and managing scalable applications.",
      icon: "icon-cloud",
    },
    {
      id: 4,
      workName: "CI/CD",
      text: "Azure CI/CD, Azure DevOps, CircleCI",
      complateProject: "Setting up automatic deployment and unit tests from scratch to Azure virtual machine.",
      icon: "icon-recycle",
    },
    {
      id: 5,
      workName: "Database",
      text: "MS SQL and NoSQL database",
      complateProject: "Database maintenance, integration within backend services utilizing different frameworks.",
      icon: "icon-attachment",
    },
  ],
  awards: [
    {
      id: 1,
      image: "logo1.png",
      ctile: "Site of the year 2020",
      date: "April 2020",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 2,
      image: "logo2.png",
      ctile: "Theme of the day 2021",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 3,
      image: "logo2.png",
      ctile: "Best design awwards",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
  ],
  contacttitle: "Contact Form",
};

export default jhonData;
