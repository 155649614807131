import React from "react";
import {useSelector} from 'react-redux';

export default function Exprience() {
  const lang = useSelector(state => state.lang);
  return (
    lang === "English" ? 
    <div className="exprience_inner">
      <div className="media exprience_item">
        <div className="media-left">
          <a href="https://www.linkedin.com/in/kai-phung" target="_blank" rel="noopener noreferrer">
            <img
              src={require("../../image/developer-img/design-logo2.png")}
              alt=""
            />
          </a>
        </div>

        <div className="media-body">
          <h4>Junior Software Designer</h4>
          <span>April 2023 - now (2 years)</span>
          <p>
          • Main client: ABB. Stakeholders: Tietoevry.
          </p>
          <p>
          • Design Scalable Application Architectures from scratch. Developed modular frontend and backend components using React TypeScript, Angular, ASP.NET, C#, and MS SQL.
          </p>
          <p>
          • Managed Azure Cloud resources, Azure AD app registrations, external API service integration, and SQL database migrations.
          </p>
          <p>
          • Deployed and managed applications on Windows Server with IIS for hosting performance maintenance.
          </p>
          <p>
          • Optimized CI/CD pipelines for efficient builds and releases, with post-deployment debugging and error detection.
          </p>
          <p>
          • Communicated and collaborated with clients to understand feature expectations, provided technical insights, and suggested
          effective services, frameworks, and modules for upcoming features.
          </p>
          <p>
          • <strong>Impact:</strong> up to <strong>30-50%</strong> reduction of cloud operation cost for several Azure applications, optimized website backend data
          retrieval time by 90% <strong>from 3 min to 15-20 sec</strong>, optimized API result size by 98% <strong>from 10+MB to 100-300kB.</strong>
          </p>
        </div> 
       </div>
      <div className="media exprience_item">
        <div className="media-left">
          <a href="https://www.linkedin.com/in/kai-phung" target="_blank" rel="noopener noreferrer">
            <img
              src={require("../../image/developer-img/design-logo1.png")}
              alt=""
            />
          </a>
        </div>
        
        <div className="media-body">
          <h4>Performance Analyst and Digital Consultant</h4>
          <span>Sept 2019 - June 2022 (3 years)</span>
          <p>
          • Conducted online analytics work for clients: The Coca-Cola Company, Domino’s Pizza Inc., MSD Corporation.
          </p>
          <p>
          • Performed data analysis using Facebook Pixel Tracking, Google Analytics, third-party analytics tools.
          </p>
          <p>
          • Tested Web-application functionality, validated UI/UX, debugged C#, and React Typescript application.
          </p>
          <p>
          • Generated comprehensive reports and dashboards, which integrated with Google & Metaverse API for real-time data tracking.
          </p>
          <p>
          • Communicated with client for data analysis details and proposed solution bi-weekly to their applications and products.
          </p>
        </div> 
      {/* </div>
      <div className="media exprience_item">
        <div className="media-left">
          <a href=".#">
            <img
              src={require("../../image/developer-img/design-logo2.png")}
              alt=""
            />
          </a>
        </div>
        <div className="media-body">
          <h4>Former Graphic Designer</h4>
          <span>March, 2011 - June, 2012 (1 year, 3 Month)</span>
          <p>
            Proven ability to lead and manage a wide variety of design and
            development projects in team and independent situations.
          </p>
        </div>
      </div>
      <div className="media exprience_item">
        <div className="media-left">
          <a href=".#">
            <img
              src={require("../../image/developer-img/design-logo3.png")}
              alt=""
            />
          </a>
        </div>
        <div className="media-body">
          <h4>Head of Design</h4>
          <span>March, 2005 - March, 2011 (6 years)</span>
          <p>
            Proven ability to lead and manage a wide variety of design and
            development projects in team and independent situations.
          </p>
        </div>
      </div>
      <div className="media exprience_item">
        <div className="media-left">
          <a href=".#">
            <img
              src={require("../../image/developer-img/design-logo4.png")}
              alt=""
            />
          </a>
        </div>
        <div className="media-body">
          <h4>Graphic Designer (intern)</h4>
          <span>March, 2015 - June, 2017 (1 year, 3 Month)</span>
          <p>
            Proven ability to lead and manage a wide variety of design and
            development projects in team and independent situations.
          </p>
        </div> */}
      </div>
    </div> : 
      <div className="exprience_inner">
        <div className="media exprience_item">
          <div className="media-left">
            <a href="https://www.linkedin.com/in/kai-phung" target="_blank" rel="noopener noreferrer">
              <img
                src={require("../../image/developer-img/design-logo2.png")}
                alt=""
              />
            </a>
          </div>
          <div className="media-body">
            <h4>Junior Software Designer</h4>
            <span>Huhtikuu 2023 - nyt (2 vuotta)</span>
            <p>
              • Pääasiakas: ABB. Sidosryhmät: Tietoevry.
            </p>
            <p>
              • Suunniteltu skaalautuvia sovellusarkkitehtuureja alusta alkaen. Kehitetty modulaarisia frontend- ja backend-komponentteja käyttäen React TypeScriptiä, Angularia, ASP.NET:ä, C#:a ja MS SQL:ää.
            </p>
            <p>
              • Hallinnoitu Azure-pilvipalveluresursseja, Azure AD -sovellusten rekisteröintejä, ulkoisia API-palveluiden integraatioita ja SQL-tietokantamigraatioita.
            </p>
            <p>
              • Käyttöönotto ja hallinnointi sovelluksia Windows Serverissä IIS:llä suorituskyvyn ylläpitoa varten.
            </p>
            <p>
              • Optimoitu CI/CD-putkia tehokkaiden buildien ja julkaisujen mahdollistamiseksi, mukaan lukien käyttöönoton jälkeinen debuggaus ja virheiden tunnistus.
            </p>
            <p>
              • Kommunikoitu ja tehty yhteistyötä asiakkaiden kanssa ominaisuuksien odotusten ymmärtämiseksi, tarjottu teknisiä näkemyksiä ja ehdotettu tehokkaita palveluita, kehyksiä ja moduuleja tulevia ominaisuuksia varten.
            </p>
            <p>
              • <strong>Vaikutus:</strong> jopa <strong>30-50%</strong> pilvipalvelutoimintakustannusten vähennys useille Azure-sovelluksille, optimoitu verkkosivuston backend-datan hakuaika 90% <strong>3 minuutista 15-20 sekuntiin</strong>, optimoitu API-tulosten koko 98% <strong>10+ MB:sta 100-300 kB:hen.</strong>
            </p>
          </div> 

      </div>
        <div className="media exprience_item">
          <div className="media-left">
            <a href="https://www.linkedin.com/in/kai-phung" target="_blank" rel="noopener noreferrer">
              <img
                src={require("../../image/developer-img/design-logo1.png")}
                alt=""
              />
            </a>
          </div>
          <div className="media-body">
            <h4>Suorituskykyanalyytikko ja Digitaalinen Konsultti</h4>
            <span>Syyskuu 2019 - Kesäkuu 2022 (3 vuotta)</span>
            <p>
              • Suorittanut verkkopohjaisia analytiikkatehtäviä asiakkaille: The Coca-Cola Company, Domino’s Pizza Inc., MSD Corporation.
            </p>
            <p>
              • Tehty datan analysointia Facebook Pixel Trackingilla, Google Analyticsillä ja kolmannen osapuolen analytiikkatyökaluilla.
            </p>
            <p>
              • Testattu verkkosovellusten toiminnallisuutta, validoitu UI/UX-suunnittelu, debugattu C#- ja React TypeScript -sovelluksia.
            </p>
            <p>
              • Luotu kattavia raportteja ja dashboardeja, jotka integroituivat Google- ja Metaverse API:hin reaaliaikaisen datan seurannaksi.
            </p>
            <p>
              • Kommunikoitu asiakkaan kanssa datan analysoinnin yksityiskohdista ja ehdotettu ratkaisuja kahden viikon välein heidän sovelluksiinsa ja tuotteisiinsa.
            </p>
        </div>
      </div>
    </div>
  );
}
