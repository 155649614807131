const jhonDataFinnish = {
  name: "John Deo.",
  name2: "Richard Tea.",
  name3: "Alexa Johnson",
  role: "Visual Designer & Front-End Developer,",
  ptext: "Creative Designer & Developer located in London.",
  socialLinks: [
    {
      name: "github",
      url: "https://github.com/KaiDoingCode",
      className: "social_blogger_square",
    },
    {
      name: "email",
      url: "mailto:vantu20896@gmail.com",
      className: "social_googleplus",
    },
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/kai-phung",
      className: "social_linkedin",
    },
  ],
  aboutme: "Esittelyni - Tu Phung",
  aboutdetails:
    "Minulaonerinomaisetongelmanratkaisutaidotjavahvataustadata-analyseistä. Olentotunutkansainvälisintyöympäristöihin,työskennelytkansainvälisesä toimistosa,mikäedelytänytintensivistäongelmanratkaisukykyäja-vastuuta.",
  service: [
    {
      id: 1,
      iconName: "icon-desktop",
      serviceTitle: "Web Design",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 2,
      iconName: "icon-pencil",
      serviceTitle: "Development",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 3,
      iconName: "icon-presentation",
      serviceTitle: "Marketing",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 4,
      iconName: "icon-linegraph",
      serviceTitle: "Strategy",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 5,
      iconName: "icon-gears",
      serviceTitle: "Support",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 6,
      iconName: "icon-tools",
      serviceTitle: "We’re experienced",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
  ],
  contact: [
    {
      id: "1",
      title: "YHTEYSTIEDOT",
      menuItems: [
        {
          id: 1,
          icon: "icon_pin_alt",
          text: "Address: Tampere - Finland",
        },
        {
          id: 2,
          icon: "icon_phone",
          text: "Puhelin: +358.41.493.0483",
        },
        {
          id: 3,
          icon: "icon_mail",
          text: "Sähköposti: vantu20896@gmail.com",
        },
        {
          id: 4,
          icon: "icon_globe",
          text: "Henkilökohtainen Webi: https://github.com/KaiDoingCode",
        },
      ],
    },
  ],
  devloperBanner: [
    {
      id: 1,
      subtile: "Ohjelmistokehittäjä",
      title: "Tu Phung",
      discription:
        "Olen intohimoinen ohjelmistokehittäjä, joka rakastaa tuotteiden luomista, jotka auttavat käyttäjiä heidän työssään. Olen utelias henkilö, joka on jatkuvasti motivoitunut kysymään, tutkimaan ja etsimään hyvin perusteltuja vastauksia esittämiini kysymyksiin. Välitän asiakkaiden tyytyväisyydestä, tiimityöstä ja koodin laadusta. Etsin junior- tai keskitason roolia."    
    },
  ],
  devAbout: [
    {
      id: 1,
      smalltitle: "Esittelyni",
      aHead: "Intohimoinen ohjelmoija",
      aHeadTwo: "Ohjelmistokehittäjän harjoittelija",
      adiscription:
        "Erinomaiset ongelmanratkaisutaidot ja vahva tausta data-analyysissä. Totuttautunut kansainvälisiin työympäristöihin, työskenneltyään kansainvälisessä virastossa ja suomalaisessa ohjelmistokonsultointiyrityksessä, joissa vaaditaan intensiivisiä ongelmanratkaisukykyjä ja vastuullisuutta.",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: "Taustajärjestelmät",
      text: "C#, .NET, Java, Node.js, Typescript",
      complateProject: "Käytännön kokemusta C#:sta ja .NET:stä taustajärjestelmien ja yrityssovellusten rakentamisessa. Taitava Java- ja Node.js-sovellusten kehittämisessä eri alustoilla ja reaaliaikaisissa ympäristöissä.",
      icon: "icon-compass"
    },
    {
      id: 2,
      workName: "Käyttöliittymät",
      text: "React, Angular",
      complateProject: "Käytännön kokemusta sekä Reactin että Angularin hyödyntämisestä dynaamisten, responsiivisten ja käyttäjäystävällisten verkkosovellusten kehittämisessä. Käyttöliittymien toteutus Figma-suunnitelmista.",
      icon: "icon-genius"
    },
    {
      id: 3,
      workName: "Pilvipalvelut",
      text: "Azure, AWS",
      complateProject: "Taitava Azuren pilvipalveluiden käytössä, mukaan lukien skaalautuvien sovellusten käyttöönotto ja hallinta.",
      icon: "icon-cloud"
    },
    {
      id: 4,
      workName: "CI/CD",
      text: "Azure CI/CD, Azure DevOps, CircleCI",
      complateProject: "Automaattisten käyttöönottojen ja yksikkötestien luominen alusta alkaen Azure-virtuaalikoneelle.",
      icon: "icon-recycle"
    },
    {
      id: 5,
      workName: "Tietokannat",
      text: "MS SQL ja NoSQL -tietokannat",
      complateProject: "Tietokantojen ylläpito ja integrointi taustapalveluihin eri kehysten avulla.",
      icon: "icon-attachment"
    }
  ],  
  awards: [
    {
      id: 1,
      image: "logo1.png",
      ctile: "Site of the year 2020",
      date: "April 2020",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 2,
      image: "logo2.png",
      ctile: "Theme of the day 2021",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 3,
      image: "logo2.png",
      ctile: "Best design awwards",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
  ],
  contacttitle: "Contact Form",
};

export default jhonDataFinnish;
