import React, { Component } from "react";
import Isotope from "isotope-layout/js/isotope";
import ImagesLoaded from "imagesloaded/imagesloaded";
import actions from "../../actions";
import { connect } from "react-redux";
import './image.css';

class DeveloperPortfolio extends Component {
  componentDidMount() {
    var imgLoad = new ImagesLoaded(".grid");

    imgLoad.on("progress", function (instance, image) {
      this.iso = new Isotope(".grid", {
        itemSelector: ".grid-item",
        layoutMode: "masonry",
      });
    });
  }

  render() {
    return (
      <section className="dev_work_area" id="portfolio">
        <div className="container custome_container">
          <div className="row grid">
            <div className="col-lg-6 col-md-6 grid-item">
              <div className="dev_tittle">
                <h2>{this.props.lang === "English" ? 'Projects' : 'Projekti'}</h2>
              </div>
            </div>
            <div className="col-md-6 grid-item">
              <div className="work_item image-overlay">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img
                    src={require("../../image/developer-img/abb_wi_preview.png")}
                    alt=""
                  />
                </a>
                <div className="content">
                  <h3>{this.props.lang === "English" ? 'ABB Working Instruction' : 'ABB Työohjeet'}</h3>
                  <div className="category">
                    <a href="#" target="_blank" rel="noopener noreferrer">Azure</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">React</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">TypeScript</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">C#</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">.NET</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">SQL</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 grid-item">
              <div className="work_item image-overlay">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img
                    src={require("../../image/developer-img/abb_res_preview.png")}
                    alt=""
                  />
                </a>
                <div className="content">
                  <h3>{this.props.lang === "English" ? 'ABB Repair Event Service' : 'ABB Korjaustapahtuma-palvelu'}</h3>
                  <div className="category">
                    <a href="#" target="_blank" rel="noopener noreferrer">Azure</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">Angular</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">TypeScript</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">C#</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">.NET</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">SQL</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 grid-item">
              <div className="work_item image-overlay">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img
                    src={require("../../image/developer-img/abb_tm_preview.png")}
                    alt=""
                  />
                </a>
                <div className="content">
                  <h3>{this.props.lang === "English" ? 'ABB Test Maintenance' : 'ABB Testihuolto'}</h3>
                  <div className="category">
                    <a href="#" target="_blank" rel="noopener noreferrer">Azure</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">React</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">TypeScript</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">C#</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">.NET</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">SQL</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 grid-item">
              <div className="work_item image-overlay">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img
                    src={require("../../image/developer-img/abb_lp_preview.png")}
                    alt=""
                  />
                </a>
                <div className="content">
                  <h3>{this.props.lang === "English" ? 'ABB Label Print' : 'ABB Tunnisteiden Tulostus'}</h3>
                  <div className="category">
                    <a href="#" target="_blank" rel="noopener noreferrer">Azure</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">React</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">TypeScript</a>                    
                    <a href="#" target="_blank" rel="noopener noreferrer">C#</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">.NET</a>
                    <a href="#" target="_blank" rel="noopener noreferrer">SQL</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 grid-item">
              <div className="work_item">
                <a href="https://d3ok3bggq9wv0q.cloudfront.net/"  target="_blank" rel="noopener noreferrer">
                  <img
                    src={require("../../image/developer-img/pr1 copy.png")}
                    alt=""
                    
                  />
                </a>
                <div className="content">
                {this.props.lang === "English" ? <h3>Cinema App</h3> : <h3>Elokuva sovellus</h3>}
                  <div className="category">
                    <a href="https://d3ok3bggq9wv0q.cloudfront.net/" target="_blank" rel="noopener noreferrer">React</a>
                    <a href="https://d3ok3bggq9wv0q.cloudfront.net/" target="_blank" rel="noopener noreferrer">Redux</a>
                    <a href="https://d3ok3bggq9wv0q.cloudfront.net/" target="_blank" rel="noopener noreferrer">AWS</a>
                    <a href="https://d3ok3bggq9wv0q.cloudfront.net/" target="_blank" rel="noopener noreferrer">CirleCI</a>
                    <a href="https://d3ok3bggq9wv0q.cloudfront.net/" target="_blank" rel="noopener noreferrer">Node</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 grid-item">
              <div className="work_item">
                <a href="https://github.com/KaiDoingCode/react-slack-chat"  target="_blank" rel="noopener noreferrer">
                  <img
                    src={require("../../image/developer-img/pr2 copy.png")}
                    alt=""
                    
                  />
                </a>
                <div className="content">
                  <h3>{this.props.lang === "English" ? 'Chat App' : 'Teksti sovellus'}</h3>
                  <div className="category">
                    <a href="https://github.com/KaiDoingCode/react-slack-chat"  target="_blank" rel="noopener noreferrer">React</a>
                    <a href="https://github.com/KaiDoingCode/react-slack-chat"  target="_blank" rel="noopener noreferrer">Redux</a>
                    <a href="https://github.com/KaiDoingCode/react-slack-chat"  target="_blank" rel="noopener noreferrer">Firebase</a>
                    <a href="https://github.com/KaiDoingCode/react-slack-chat"  target="_blank" rel="noopener noreferrer">CircleCI</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 grid-item">
              <div className="work_item">
                <a href="https://github.com/KaiDoingCode/dotNETEcomApp"  target="_blank" rel="noopener noreferrer">
                  <img
                    src={require("../../image/developer-img/dotNetapp_preview.png")}
                    alt=""
                  />
                </a>
                <div className="content">
                  <h3>{this.props.lang === "English" ? 'Movie Buying App' : 'Verkko elokuva kauppa sovellus'}</h3>
                  <div className="category">
                    <a href="https://github.com/KaiDoingCode/dotNETEcomApp"  target="_blank" rel="noopener noreferrer">C#</a>
                    <a href="https://github.com/KaiDoingCode/dotNETEcomApp"  target="_blank" rel="noopener noreferrer">ASP.NET</a>
                    <a href="https://github.com/KaiDoingCode/dotNETEcomApp"  target="_blank" rel="noopener noreferrer">Blazor</a>
                    <a href="https://github.com/KaiDoingCode/dotNETEcomApp"  target="_blank" rel="noopener noreferrer">Azure</a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6 grid-item">
              <div className="work_item">
                <a href=".#">
                  <img
                    src={require("../../image/developer-img/pr3.jpg")}
                    alt=""
                  />
                </a>
                <div className="content">
                  <h3>John</h3>
                  <div className="category">
                    <a href=".#">React</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 grid-item">
              <a href=".#" className="dev_btn">
                ALL Projects
              </a>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({ 
  EngMode: () => dispatch(actions.EngMode()),
  FinMode: () => dispatch(actions.FinMode())
});


const mapStateToProps = state => ({
  lang: state.lang
});

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperPortfolio);